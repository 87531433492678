.debtors-modal-header {
  text-align: left !important;
}

.view-debtor-detail-container {
  @extend .add-overdue-content;
  gap: 10px 5%;
  width: 100%;
  padding: 15px;
}

.view-debtor-value {
  min-height: 32px;
  padding: 0.344rem;
  font: $subtitle $font-medium;
  color: $field-color;
}

.view-debtor-detail-field-container {
  display: grid;
  grid-template-columns: 155px 1fr;
}

.view-debtor-detail-title {
  margin-top: 5px;
  color: $primary-color;
}
