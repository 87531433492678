.accordion-item.application-person-step-accordion {
  background-color: $background-color;
}

.application-person-step-accordion-item,
.application-confirmation-step {
  display: grid;
  grid-template-columns: 15% calc(35% - 15px) 15% calc(35% - 15px);
  align-items: center;
  padding: 5px 30px 30px;
  gap: 15px;
  font: $subtitle $font-regular !important;

  > span {
    color: $primary-color;
  }
  .react-select-container,
  .input-container,
  .date-picker-container {
    width: 70%;
    min-width: 170px;
  }

  .radio-container {
    display: flex;
    align-items: center;
    grid-column: 1 / span 4;
  }

  .grid-checkbox,
  .title,
  .main-title,
  .horizontal-line,
  .radio-title,
  .main-title-director-detail {
    grid-column: 1 / span 4;
  }

  .grid-checkbox label {
    color: $primary-color;
  }

  .main-title {
    font: $title $font-medium;
    color: $secondary-color;
    padding: 5px 0;
  }
  .main-title-director-detail {
    font: $title $font-medium;
    color: $primary-color;
    padding: 5px 0;
  }

  .title {
    color: $secondary-color;
  }

  .horizontal-line {
    height: 1px;
    margin: 0 -20px;
    background-color: $border-color;
  }

  .detail-value {
    width: 80%;
    min-width: 180px;
    padding: 5px 10px;
    color: $field-color;
    background-color: $border-color;
    border-radius: 5px;
    min-height: 28px;
  }

  .radio-buttons {
    grid-column: 1 / span 4;
  }

  .long-text {
    grid-column: 2 / span 3;
    width: 100%;
    padding: 5px 10px;
    color: $field-color;
    background-color: $border-color;
    border-radius: 5px;
  }
}
.is-or-container {
  position: relative;
}
.is-or-container::after {
  content: 'or';
  position: absolute;
  bottom: -16px;
  left: calc(50% - 8px);
  font: $subtitle $font-medium;
  color: $primary-color;
}

.is-or-person-step::after {
  left: calc(35% - 8px);
}