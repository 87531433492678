.if-yes-row,
.credit-limits-note,
.upload-document-row {
  span {
    font-size: $subtitle;
  }

  .input-container {
    flex: 1;
    margin-top: 10px;
  }
}

.step-content textarea {
  grid-column: 2/ 7;
  width: 100%;
  margin-top: 5px;
  font: $subtitle $font-regular;
  color: $field-color;
  word-break: break-all;
}

.credit-limit-amount {
  display: grid;
  grid-template-columns: 75px 1fr 155px 1fr;
  gap: 0 10px;
  width: 100%;
  margin: 10px 0 20px 0;

  span {
    margin-top: 7px;
    white-space: nowrap;
    font-size: $subtitle;
  }

  .input-container {
    width: 100%;
  }
}

.application-credit-limits-grid {
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  gap: 20px 30px;
}

.outstanding-amount-container {
  grid-column-start: 1;
}
