.import-application-modal {
  width: 80%;
  display: flex;
  flex-direction: column;
}

//stepper-progress
.ia-stepper-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ia-stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.ia-stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid $border-color;
  width: 100%;
  top: 10px;
  left: -50%;
}
.ia-stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid $border-color;
  //width: 100%;
  top: 10px;
  left: 50%;
}
.ia-stepper-item .ia-step-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid $primary-color;
  background-color: white;
  z-index: 1;
}
.ia-step-name {
  font-size: $field-name;
  color: $placeholder-color;
}
.ia-step-name.ia-done-step {
  color: $primary-color;
}
.ia-stepper-item:first-child::before {
  content: none;
}
.ia-stepper-item:last-child::after {
  content: none;
}
.ia-stepper-item.ia-done-step::before {
  border-color: $primary-color;
}
.ia-stepper-item.ia-done-step::after {
  border-color: $primary-color;
}
.ia-step-circle.ia-done-step {
  background-color: $primary-color;
}
//end

.ia-step-content {
  width: 100%;
  height: 50vh;
  max-height: 50vh;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
}
.ia-step-button-row {
  width: 100%;
  margin-top: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
}
