.upload-document-modal,
.add-task-modal {
  width: 60vw;
  .document-upload-popup-container > span {
    font-size: $subtitle;
  }
}

.document-upload-popup-container,
.add-notes-popup-container,
.modify-credit-limit-container {
  display: grid;
  grid-template-columns: 180px 2fr;
  gap: 15px 5px;

  > span {
    color: $primary-color;
  }

  .upload-document-input {
    width: 180px;
  }
  .switch-container {
    width: 180px;
  }
  .document-upload-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 0.063rem solid #e7ecf2;
    height: 32px;
    width: 180px;
    button {
      border: none;
      color: #b2b9bf;
      background: none;
    }
    p {
      color: #b2b9bf;
    }
  }
}

.tab-table-container {
  width: 100%;
  overflow-x: auto;
}
