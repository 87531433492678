.notification-container {
  margin-top: 10px;
}

.notification-row {
  display: flex;
  margin: 0 0 5px 25px;
}

.notification-detail-row {
  flex: 1;
  display: grid;
  grid-template-columns: calc(70% - 40px) 20% 10%;
  gap: 0 20px;
  padding: 10px;
  background-color: $background-color;
  border-radius: 5px;

  .material-icons-round {
    margin-right: 7px;
    font-size: $h4;
    color: $error-color;
    text-align: right;
    cursor: pointer;
  }

  .material-icons-round:hover {
    color: $error-variant-color;
  }
}

.notification-row:last-child {
  margin-bottom: 0;
}

.notification-date {
  width: fit-content;
  margin-top: 30px;
  padding: 10px;
  font-size: $text;
  color: white;
  background-color: $primary-color;
  border-radius: 5px;
  z-index: 1;
}

.notification-date:first-child {
  margin-top: 10px;
}

.notification-time {
  font: $subtitle $font-medium;
  color: $primary-color;
  text-align: center;
}

.notification-circle-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.notification-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  min-height: 26px;
  min-width: 26px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
  img {
    width: 18px;
  }
}

.notification-vertical-line {
  position: absolute;
  left: 50%;
  height: calc(100% + 20px);
  width: 1px;
  background-color: $primary-color-1-variant-color;
}

.notification-row:last-child .notification-vertical-line {
  bottom: 50%;
  height: calc(28% + 20px);
}
