$primary-color: #0073ab;
$primary-variant-color: #38558f;
$primary-color-1: #e2e9f0;
$primary-color-1-variant-color: #cbd7e4;
$secondary-color: #f6a457;
$secondary-color-light: #fdf0e4;
$secondary-variant-color: #f49745;
$border-color: #e7ecf2;
$placeholder-color: #b8c1cc;
$field-color: #66737f;
$background-color: #f2f5f8;
$success-color: #82a998;
$success-variant-color: #62d493;
$error-color: #fe5050;
$error-variant-color: #fe6767;
$error-color-light: #feebeb;
$alert-blue: #206dff;
$alert-blue-light: #e8f0ff;
