.common-detail-container {
  width: 100%;
  margin-top: 25px;
  padding: 20px;
  font: $sub-text;
  background-color: white;
  border-radius: 10px;
}

.common-detail-grid {
  display: grid;
  grid-template-columns: calc(50% - 5%) calc(50% - 5%);
  grid-template-areas: 'r1c1 r1c2' 'r2c1 r2c2' 'r3c1 r3c1';
  gap: 15px 10%;
}

.common-detail-field.user-select-client {
  grid-column: 1 / span 2;
}

.common-detail-field {
  display: grid;
  align-items: center;
  grid-template-columns: 175px 1fr;
}

.common-detail-title {
  min-width: 120px;
  margin-right: 20px;
  color: $primary-color;
}

.module-container {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.module {
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;

  label {
    margin: 5px 0;
  }
}

.module-title {
  margin-bottom: 10px;
  font: $h5 $font-medium;
  color: $primary-color;
}

.checkbox-disabled {
  * {
    cursor: not-allowed;
  }
}

.add-user-detail-container span {
  padding: 0 10px;
  font: $subtitle $font-medium;
  color: $field-color;
}
