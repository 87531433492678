.common-detail-field.view-insurer-website {
  grid-column: 1 / span 2;

  a {
    padding: 2px 5px;
    text-decoration: none;
    font: $subtitle $font-medium;
    color: $field-color;
  }
}

.view-insurer-grid .common-detail-field {
  grid-template-columns: 130px 1fr;
  align-items: baseline;
  .common-detail-title {
    margin-top: 5px;
  }
}

.view-insurer-value {
  @extend .view-debtor-value;
  min-height: unset;
  padding: 2px 5px;
}
