.settings-tab.tab-container {
  background-color: white;
}

.settings-api-integration-container.common-white-container {
  justify-content: center;
  padding: 0;
}

.settings-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .title {
    font: $h5 $font-medium;
    color: $primary-color;
  }
}

.settings-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 10px 20px;
  border-bottom: 1px solid $border-color;

  .title {
    font: $h5 $font-medium;
    color: $secondary-color;
  }

  textarea {
    width: 100%;
  }
}

.settings-row:first-child {
  margin-top: 10px;
}

.settings-row:last-child {
  margin-bottom: 10px;
  border-bottom: 0;
}

.settings-row > div:first-child {
  flex: 1;
}

.settings-input-container {
  display: grid;
  grid-template-columns: repeat(3, 100px minmax(100px, 350px));
  gap: 10px;
  align-items: center;
  margin: 15px 30px 0 0;

  .rss-access-token,
  textarea {
    grid-column: 2/ 7;
    $font: $subtitle $font-regular;
    color: $field-color;
    word-break: break-all;
  }
}

.equifax-row-container {
  grid-template-columns: repeat(2, 80px minmax(150px, 400px));
}

.settings-row-input-title {
  margin-right: 10px;
  font: $subtitle $font-regular;
  color: $primary-color;
}

.common-white-container.settings-organization-details {
  flex-direction: column;
  padding: 30px;
}

.add-document-modal {
  width: 500px;
}

.settings-organization-details-row,
.add-document-modal-body {
  display: grid;
  grid-template-columns: 150px minmax(200px, 400px);
  gap: 10px;
  align-items: center;
  font-size: $subtitle;

  span {
    color: $primary-color;
  }
}

.add-document-modal-body {
  grid-template-columns: 150px minmax(200px, 300px);
  gap: 15px;
}

.settings-audit-log-list-container {
  max-height: calc(100vh - 315px);
  overflow-y: auto;
}

.abn-guid-input {
  grid-column: 2/5;
}

.settings-equifax-username {
  grid-column: 2/3;
}

.settings-equifax-password {
  grid-column: 5/7;
}

.settings-no-value-error {
  font: $subtitle $font-regular;
  color: $error-color;
}

.settings-organization-details-row {
  grid-template-columns: 150px minmax(200px, 800px);
  align-items: baseline;
  span {
    margin-top: 5px;
  }
}

.settings-organization-details-value {
  padding: 0.344rem;
  font: $subtitle $font-medium;
  color: $field-color;
}
