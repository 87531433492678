.report-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.report {
  min-height: 180px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;

  .icon {
    margin-bottom: 20px;
    padding: 10px;
    color: #ffffff;
    background-color: $primary-color;
    border-radius: 10px;
  }

  .report-title {
    font: $h4 $font-medium;
    color: $primary-color;
  }
}

.report:hover {
  background-color: $primary-color;
  color: #ffffff;

  .icon {
    color: $primary-color;
    background-color: #ffffff;
  }

  .report-title {
    color: #ffffff;
  }
}

.report-tooltip {
  text-transform: lowercase;
}
