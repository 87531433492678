.react-select-container .react-select__control {
  max-height: 32px;
  min-height: 32px;
  width: 100%;
  padding: 2px 5px 2px 8px;
  border-color: $border-color !important;
  background-color: white;
  box-shadow: none;
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.isMulti-react-select .react-select__control {
  min-height: unset !important;
  max-height: unset !important;
}

.react-select-container .react-select__control .react-select__value-container {
  padding: 0 8px;
}

.react-select__control:focus,
.react-select-container {
  border-color: $border-color !important;
  outline: none !important;
  box-shadow: none !important;
}

.react-select-container .react-select__control {
  padding-left: 5px;
  .react-select__value-container {
  font: $subtitle $font-regular;
  color: $field-color;
}
}

.react-select-container .react-select__single-value {
  display: flex;
  align-items: center;
  color: $field-color;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator {
  padding: 0 0 0 8px !important;
}

.react-select__menu {
  display: inline-block !important;
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 99999 !important;
}

.react-select__menu * {
  font: $subtitle $font-regular !important;
  color: $field-color !important;
}

.react-select__option {
  -webkit-tap-highlight-color: unset !important;
  outline: none !important;
}

.react-select__option:hover,
.react-select__option--is-focused {
  background-color: #deebff;
  color: $field-color;
}

.react-select__option--is-selected {
  background-color: $primary-color !important;
  color: #ffffff !important;
}

.react-select__indicators {
  height: 26px !important;
  align-self: center !important;
}

.react-select__multi-value,
.react-select__multi-value__label {
  padding: 2px 5px !important;
  font-size: $text !important;
  background-color: $primary-color !important;
  color: #ffffff !important;
  border-radius: 5px !important;
}

.react-select__control .react-select__multi-value__remove {
  justify-content: center;
  align-items: center;
  height: 14px;
  width: 14px;
  margin: 0 3px;
  padding: 0;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;

  svg {
    height: 12px;
    width: 12px;
    fill: $primary-color;
    stroke: $primary-color;
  }
}

.react-select__control .react-select__multi-value__remove:hover svg {
  fill: $error-color;
  stroke: $error-color;
}

.react-select__control--is-disabled {
  background-color: #fff !important;
  border: none !important;

  .react-select__indicators,
  .react-select__multi-value__remove {
    display: none;
  }

  .react-select__single-value {
    font: $subtitle $font-medium;
  }
}

.isMulti-react-select.react-select--is-disabled {
  padding: 0 !important;
}

.date-picker-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 6px;
  background-color: #ffffff;
  border: 0.063rem solid $border-color;
  border-radius: 0.313rem;

  input {
    width: 100%;
    font: $subtitle $font-regular;
    color: $field-color;
    background-color: transparent !important;
    border: none;
    outline: none;
    cursor: pointer;
  }

  span {
    //position: absolute;
    right: 5px;
    font-size: 24px;
    color: $placeholder-color;
    z-index: 1;
  }
  .react-datepicker__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    .react-datepicker__header__dropdown {
      padding-top: 15px;
      .react-datepicker__year-read-view,
      .react-datepicker__month-read-view {
        display: flex;
        flex-direction: row-reverse;
      }
      .react-datepicker__year-dropdown,
      .react-datepicker__month-dropdown,
      .react-datepicker__month-year-dropdown {
        z-index: 2 !important;
      }
      .react-datepicker__navigation {
        top: 0;
      }
    }
  }
}

.date-picker-container.cursor-default input {
  cursor: default;
}

.react-datepicker-wrapper {
  z-index: 2;
  width: 100%;
}

.react-select__multi-value {
  align-items: center;
  margin: 2px 5px 2px 2px !important;
}

.task-select.react-select-container.react-select--is-disabled .react-select__control.react-select__control--is-disabled {
  border: 1px solid $border-color !important;
}

.react-datepicker {
  min-height: 245px;
  min-width: 310px;
  overflow: hidden;
  border-color: $border-color;
  box-shadow: 0px 5px 10px #1b252f0d;
  z-index: 10;
}

.date-picker-container .material-icons-round {
  position: absolute;
}

.react-datepicker__header {
  border: none;
  background-color: white;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  padding: 5px 15px 0 15px;
  font-family: $font-regular;
  font-size: 14px;
  color: $field-color;
}

.date-picker-container .react-datepicker__header .react-datepicker__header__dropdown span {
  font-family: $font-regular;
  font-size: 14px;
  color: $field-color;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  margin-bottom: -7px;
  border-top-width: 5px;
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-color: $field-color;
}

.date-picker-container .react-datepicker__header .react-datepicker__header__dropdown span:before {
  display: none;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  padding: 10px 0;
  font-family: $font-regular;
  font-size: 14px;
  color: $field-color;
  background-color: white;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  display: flex;
  align-items: center;
  height: 25px;
  padding-left: 10px;
}

.react-datepicker__month-dropdown {
  max-height: 200px;
  overflow-y: auto;
}

.react-datepicker__month-option--selected,
.react-datepicker__year-option--selected {
  padding-right: 10px;
  font: $h6 $font-bold !important;
  color: $primary-color !important;
  text-align: end;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: $border-color;
}

.react-datepicker__month-container {
  min-height: 245px;
  min-width: 310px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  justify-content: center;
  display: inline-flex;
  align-items: center;
  width: 1.7rem;
  padding: 8px 18px;
  margin: 0.166rem;
  line-height: 1;
  font-size: 14px;
  font-family: $font-regular;
  color: $field-color;
}

.react-datepicker__day-name {
  padding: 3px 18px;
}

.react-datepicker__month {
  margin: 0 0.4rem 0.4rem 0.4rem;
}

.react-datepicker__day:focus {
  outline: none;
}

.react-datepicker__day-names {
  margin-top: 20px;
  text-transform: uppercase;
}

.react-datepicker__day-name,
.react-datepicker__day--outside-month {
  opacity: 0.5;
}

.react-datepicker__day--keyboard-selected {
  background-color: $background-color !important;
  color: $field-color !important;
  border-radius: 0.3rem !important;
}

.react-datepicker__day--today {
  background-color: $secondary-color !important;
  color: white !important;
  border-radius: 0.3rem !important;

  &:hover {
    background-color: $secondary-variant-color;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  font-family: $font-regular;
  font-size: 14px;
  background-color: $primary-color;
  color: white;
  border-radius: 0.3rem;
}

.react-datepicker__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 15px;
  width: 15px;
  height: 15px;

  &:focus {
    outline: none;
  }
}

.react-datepicker__navigation--previous {
  top: 10px;
  left: 20px;
  padding: 10px;
  color: $primary-color;
  border: none;
  background-color: $primary-color;
  border-radius: 3px;

  &:hover {
    background-color: $primary-variant-color;
  }
}

.react-datepicker__navigation--previous:before {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  left: 7px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: none;
  border-bottom: none;
  transform: rotate(-45deg);
}

.react-datepicker__navigation--next {
  top: 10px;
  right: 20px;
  padding: 10px;
  color: $primary-color;
  background-color: $primary-color;
  border: none;
  border-radius: 3px;

  &:hover {
    background-color: $primary-variant-color;
  }
}

.react-datepicker__navigation--next:before {
  content: '';
  position: absolute;
  right: 7px;
  height: 6px;
  width: 6px;
  border-left: none;
  border-top: none;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(-45deg);
}

.react-datepicker__navigation--previous:disabled,
.react-datepicker__navigation--next:disabled {
  background-color: #b2b8be;
  cursor: not-allowed;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  position: relative;
  margin-top: -10px;
  padding: 10px;
  color: #0073ab;
  border: none;
  background-color: #0073ab;
  border-radius: 3px;
}

.react-datepicker__month-read-view--down-arrow:after,
.react-datepicker__year-read-view--down-arrow:after {
  content: '';
  position: absolute;
  height: 7px;
  width: 7px;
  top: 5px;
  left: 7px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: none;
  border-bottom: none;
  transform: rotate(-135deg);
}

// for month and year picker starts here

.month-year-picker {
  .react-datepicker {
    max-height: 220px;
    min-height: 220px;
  }

  .react-datepicker-year-header {
    padding: 15px 15px 0;
  }

  .react-datepicker__month {
    margin: 20px;
  }

  .react-datepicker__month-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    gap: 10px;
    margin: 5px 0;
  }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    font-family: $font-regular;
    color: $field-color;
  }

  .react-datepicker__month .react-datepicker__month-text.react-datepicker__month--disabled {
    color: $placeholder-color;
    cursor: not-allowed;
  }

  .react-datepicker__month-text.react-datepicker__month-text--keyboard-selected {
    background-color: $primary-color;
    color: #ffffff;
  }
}

.tooltip-top-class {
  max-width: 500px;
  overflow: hidden;

  .rc-tooltip-arrow {
    left: 25%;
    border-top-color: $primary-color !important;
    opacity: 0.95;
  }
}

.rc-tooltip-inner {
  background-color: $primary-color !important;
  border: 1px solid $primary-color;
  opacity: 0.95;
}

.tooltip-left-class {
  max-width: 500px;
  overflow: hidden;

  .rc-tooltip-arrow {
    border-left-color: $primary-color !important;
    opacity: 0.95;
  }
}
