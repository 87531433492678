@import '../Clients/ViewClient/ViewClient';

.my-work-add-task-container {
  @extend .client-details-container;
}

.my-work-view-task-container {
  display: grid !important;
  grid-template-columns: 15% calc(35% - 22px) 15% calc(35% - 22px);
  padding: 25px 30px;
  gap: 15px;

  > span {
    color: $primary-color;
  }

  .client-detail {
    padding: 0.344rem;
    min-width: 170px;
    color: $field-color;
    cursor: not-allowed;
  }

  input {
    cursor: default !important;
  }
}

.my-work-tab-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-work-tab {
  width: fit-content !important;
  background-color: white !important;
}

.my-work-tab-content-container {
  margin-top: -55px;
  flex-direction: column;
}

.my-work-tab-button-row .react-datepicker-popper {
  left: unset !important;
  right: 0;
}
.common-white-container.notification-white-container {
  max-height: calc(100vh - 11rem);
  display: block;
  margin-top: 20px;
  overflow: auto;
}
.my-work-task-action-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
