.side-menu-container {
  width: 13.438rem;
  height: calc(100vh - 2rem);
  background-color: #ffffff;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 1.875rem #82828229;
}

.side-menu-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.438rem;
  margin: 0.438rem;
  border-bottom: 0.063rem solid $border-color;

  img {
    height: 2.188rem;
  }
}

.menu-container {
  max-height: calc(100% - 4.313rem);
  padding: 0.625rem 0 0 0.438rem;
  overflow-y: auto;
}

.menu {
  display: flex;
  align-items: center;
  width: calc(100% - 0.438rem);
  margin-bottom: 0.625rem;
  padding: 0.625rem;
  font: $subtitle $font-regular;
  color: $placeholder-color;
  border-radius: 0.625rem;
  text-decoration: none;

  span {
    margin-right: 0.313rem;
    font-size: $h4;
    color: $placeholder-color;
  }
}

.menu:hover:not(.active) {
  color: $primary-color;
  span {
    color: $primary-color;
  }
}

.active {
  background-color: $primary-color;
  color: white;
  span {
    color: white;
  }
}
