.client-details-container {
  display: grid !important;
  grid-template-columns: 15% calc(35% - 22px) 15% calc(35% - 22px);
  padding: 25px 30px;
  gap: 15px;

  > span {
    margin-top: 7px;
    color: $primary-color;
  }

  .client-detail {
    padding: 0.344rem;
    min-width: 170px;
    color: $field-color;
  }

  input {
    cursor: default !important;
  }
}

.react-select--is-disabled.view-client-select.react-select-container .react-select__control{
  padding: 2px 5px;
}

.view-client-select .react-select__control--is-disabled {
  .react-select__single-value {
    font: $subtitle $font-regular;
  }
}

view-client-automate-application-switch:disabled ~ .view-client-automate-application-switch-label {
  cursor: not-allowed;
}
